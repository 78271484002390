<script>
import { usePageStore } from '@voix/store/pageStore'
import { ErrorMessage, Field, Form as VeeForm } from 'vee-validate'
import * as zod from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { markRaw } from 'vue'
import Modal from './utilities/Modal'

export default {
  name: 'SignUpForm',
  components: {
    ErrorMessage,
    VeeForm,
    Modal,
    Field,
  },

  inject: ['currentResort'],

  props: {
    language: {
      type: String,
      default: 'en',
    },
    formId: {
      type: String,
      required: true,
    },
    relaxed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hideOnPage: false,
      showForm: false,

      formSchema: markRaw(
        toTypedSchema(
          zod.object({
            emailAddress: zod.string().email(),
            firstName: zod.string().min(1, { message: 'First Name is required' }),
            lastName: zod.string().min(1, { message: 'Last Name is required' }),
            country: zod.string().min(1, { message: 'Country is required' }),
            state: zod.string().optional(),
            IsTravelAgent: zod.boolean().optional(),
            TCAgree: zod.boolean({ message: 'You must agree to the terms and conditions' }),
          }).refine(schema => schema.country === 'US' ? !!schema.state : true, {
            path: ['state'],
            message: 'State is required when you are located in the US',
          }),
        ),
      ),

      form: {
        Language_Code: 'en',
        country: '',
      },
      success: {
        show: false,
        message: 'success',
      },
      error: {
        message: 'error',
      },
      shouldShowError: false,
      validationErrors: [],
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    settings() {
      return this.currentResort.footer_settings
    },
  },

  mounted() {
    this.form.Language_Code = this.language.toUpperCase()
  },

  methods: {
    submit(values) {
      const formData = values
      formData.formId = this.formId

      if (formData.country !== 'US')
        formData.state = ''

      if (formData.TCAgree)
        formData.TCAgree = 'Yes'
      else
        formData.TCAgree = 'No'

      if (formData.IsTravelAgent)
        formData.IsTravelAgent = 'Yes'
      else
        formData.IsTravelAgent = 'No'

      $voixFetch('/api/divinci/web-email-registration', { method: 'POST', body: formData })
        .then(() => {
          this.showSuccess()
        })
        .catch(() => {
          this.showError(
            $t('there-was-a-problem'),
          )
        })
    },

    showError(msg) {
      this.error.message = msg
      this.shouldShowError = true
    },

    showSuccess() {
      this.success.show = true
      this.success.message = this.$t('thank-you')

      try {
        dataLayer.push({
          userId: this.hash(this.form.emailAddress),
          event: 'newsletter',
        })
      }
      catch (e) {
        console.error(e)
      }
    },
    hash(string) {
      let hash = 0
      let i
      let chr
      if (string.length === 0)
        return hash
      for (i = 0; i < string.length; i++) {
        chr = string.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0
      }
      return Math.abs(hash)
    },
  },
}
</script>

<template>
  <div v-if="!hideOnPage">
    <div v-if="showForm">
      <Teleport to="#portal-destination">
        <Modal key="sign-up-form" @close="showForm = false">
          <p
            class="float-right uppercase font-bold text-xs cursor-pointer"
            @click="showForm = false"
          >
            Close
          </p>

          <h2 class="uppercase font-thin mb-4">
            {{ $t('sign-up-to-save') }}
          </h2>

          <VeeForm :validation-schema="formSchema" @submit="submit">
            <p v-if="!success.show" class="mb-4">
              {{ $t('receive-via-email') }}
            </p>

            <div
              v-if="shouldShowError"
              v-cloak
              class="my-4 text-red"
            >
              <p>{{ error.message }}</p>
              <ul class="text-sm">
                <li
                  v-for="(msg, index) in validationErrors"
                  :key="index"
                >
                  {{ msg }}
                </li>
              </ul>
            </div>

            <div v-if="success.show" v-cloak class="success">
              <h6 class="uppercase text-sm font-bold mb-2">
                Form successfully submitted!
              </h6>
              <p>{{ success.message }}</p>
            </div>

            <template v-if="!success.show">
              <div class="flex flex-col md:flex-row md:space-x-2 mb-2 text-sm">
                <div class="flex flex-col space-y-2">
                  <Field
                    type="text"
                    name="firstName"
                    :placeholder="`${$t('first-name')}*`"
                    class="w-full md:w-auto border-transparent bg-gray-100 text-gray-700"
                  />
                  <ErrorMessage name="firstName" class="text-red-700 bg-red-200 p-2 rounded" />
                </div>

                <div class="flex flex-col space-y-2">
                  <Field
                    type="text"
                    name="lastName"
                    :placeholder="`${$t('last-name')}*`"
                    class="w-full md:w-auto border-transparent bg-gray-100 text-gray-700"
                  />
                  <ErrorMessage name="lastName" class="text-red-700 bg-red-200 p-2 rounded" />
                </div>

                <div class="flex flex-col space-y-2">
                  <Field
                    type="email"
                    name="emailAddress"
                    :placeholder="
                      `${$t('email')}*`
                    "
                    class="w-full md:w-auto border-transparent bg-gray-100 text-gray-700"
                  />

                  <ErrorMessage name="emailAddress" class="text-red-700 bg-red-200 p-2 rounded" />
                </div>
              </div>
              <div class="flex flex-col md:flex-row md:space-x-2">
                <div class="flex flex-col md:flex-row mb-2">
                  <div class="flex flex-col space-y-2">
                    <Field
                      v-model="form.country"
                      as="select"
                      name="country"
                      class="w-full md:w-auto border-transparent bg-gray-100 text-gray-700"
                    >
                      <option
                        value
                        selected="selected"
                        disabled
                      >
                        {{
                          $t(
                            'select-country',
                          )
                        }}*
                      </option>
                      <option value="US">
                        United States
                      </option>
                      <option value="CA">
                        Canada
                      </option>
                      <option value="GB">
                        United Kingdom
                      </option>
                      <option value="AF">
                        Afghanistan
                      </option>
                      <option value="AX">
                        Aland Islands
                      </option>
                      <option value="AL">
                        Albania
                      </option>
                      <option value="DZ">
                        Algeria
                      </option>
                      <option value="AS">
                        American Samoa
                      </option>
                      <option value="AD">
                        Andorra
                      </option>
                      <option value="AO">
                        Angola
                      </option>
                      <option value="AI">
                        Anguilla
                      </option>
                      <option value="AQ">
                        Antarctica
                      </option>
                      <option value="AG">
                        Antigua &amp; Barbuda
                      </option>
                      <option value="AR">
                        Argentina
                      </option>
                      <option value="AM">
                        Armenia
                      </option>
                      <option value="AW">
                        Aruba
                      </option>
                      <option value="AU">
                        Australia
                      </option>
                      <option value="AT">
                        Austria
                      </option>
                      <option value="AZ">
                        Azerbaijan
                      </option>
                      <option value="BS">
                        Bahamas
                      </option>
                      <option value="BH">
                        Bahrain
                      </option>
                      <option value="BD">
                        Bangladesh
                      </option>
                      <option value="BB">
                        Barbados
                      </option>
                      <option value="BY">
                        Belarus
                      </option>
                      <option value="BE">
                        Belgium
                      </option>
                      <option value="BZ">
                        Belize
                      </option>
                      <option value="BJ">
                        Benin
                      </option>
                      <option value="BM">
                        Bermuda
                      </option>
                      <option value="BT">
                        Bhutan
                      </option>
                      <option value="BO">
                        Bolivia
                      </option>
                      <option value="BQ">
                        Bonaire
                      </option>
                      <option value="BA">
                        Bosnia And Herzegowina
                      </option>
                      <option value="BW">
                        Botswana
                      </option>
                      <option value="BV">
                        Bouvet Island
                      </option>
                      <option value="BR">
                        Brazil
                      </option>
                      <option value="IO">
                        British Indian Ocean Territory
                      </option>
                      <option value="VG">
                        British Virgin Islands
                      </option>
                      <option value="BN">
                        Brunei Darussalam
                      </option>
                      <option value="BG">
                        Bulgaria
                      </option>
                      <option value="BF">
                        Burkina Faso
                      </option>
                      <option value="BI">
                        Burundi
                      </option>
                      <option value="KH">
                        Cambodia
                      </option>
                      <option value="CM">
                        Cameroon
                      </option>
                      <option value="CV">
                        Cape Verde
                      </option>
                      <option value="KY">
                        Cayman Islands
                      </option>
                      <option value="CF">
                        Central African Republic
                      </option>
                      <option value="TD">
                        Chad
                      </option>
                      <option value="CD">
                        Channel Islands
                      </option>
                      <option value="CL">
                        Chile
                      </option>
                      <option value="CN">
                        China
                      </option>
                      <option value="CX">
                        Christmas Island
                      </option>
                      <option value="CC">
                        Cocos (keeling) Islands
                      </option>
                      <option value="CO">
                        Colombia
                      </option>
                      <option value="KM">
                        Comoros
                      </option>
                      <option value="CG">
                        Congo
                      </option>
                      <option value="CK">
                        Cook Islands
                      </option>
                      <option value="CR">
                        Costa Rica
                      </option>
                      <option value="CI">
                        Cote D'ivoire/ivory Coast
                      </option>
                      <option value="HR">
                        Croatia
                      </option>
                      <option value="CU">
                        Cuba
                      </option>
                      <option value="BW">
                        Curacoa
                      </option>
                      <option value="CY">
                        Cyprus
                      </option>
                      <option value="CZ">
                        Czech Republic
                      </option>
                      <option value="CI">
                        Cã´te D'ivoire
                      </option>
                      <option value="CD">
                        Democratic Republic Of The Congo
                      </option>
                      <option value="DK">
                        Denmark
                      </option>
                      <option value="DJ">
                        Djibouti
                      </option>
                      <option value="DM">
                        Dominica
                      </option>
                      <option value="DO">
                        Dominican Republic
                      </option>
                      <option value="TP">
                        East Timor
                      </option>
                      <option value="EC">
                        Ecuador
                      </option>
                      <option value="EG">
                        Egypt
                      </option>
                      <option value="SV">
                        El Salvador
                      </option>
                      <option value="EN">
                        England
                      </option>
                      <option value="GQ">
                        Equatorial Guinea
                      </option>
                      <option value="ER">
                        Eritrea
                      </option>
                      <option value="EE">
                        Estonia
                      </option>
                      <option value="ET">
                        Ethiopia
                      </option>
                      <option value="FO">
                        Faeroe Islands
                      </option>
                      <option value="FK">
                        Falkland Islands
                      </option>
                      <option value="ZZ">
                        Fictitious Points
                      </option>
                      <option value="FJ">
                        Fiji
                      </option>
                      <option value="FI">
                        Finland
                      </option>
                      <option value="FR">
                        France
                      </option>
                      <option value="GF">
                        French Guiana
                      </option>
                      <option value="PF">
                        French Polynesia
                      </option>
                      <option value="TF">
                        French Southern Territories
                      </option>
                      <option value="GA">
                        Gabon
                      </option>
                      <option value="GM">
                        Gambia
                      </option>
                      <option value="GE">
                        Georgia
                      </option>
                      <option value="DE">
                        Germany
                      </option>
                      <option value="GH">
                        Ghana
                      </option>
                      <option value="GI">
                        Gibralter
                      </option>
                      <option value="GR">
                        Greece
                      </option>
                      <option value="GL">
                        Greenland
                      </option>
                      <option value="GD">
                        Grenada
                      </option>
                      <option value="GP">
                        Guadeloupe
                      </option>
                      <option value="GU">
                        Guam
                      </option>
                      <option value="GT">
                        Guatemala
                      </option>
                      <option value="GG">
                        Guernsey
                      </option>
                      <option value="GN">
                        Guinea
                      </option>
                      <option value="GW">
                        Guinea-bissau
                      </option>
                      <option value="GY">
                        Guyana
                      </option>
                      <option value="HT">
                        Haiti
                      </option>
                      <option value="HM">
                        Heard And Mcdonald Islands
                      </option>
                      <option value="HN">
                        Honduras
                      </option>
                      <option value="HK">
                        Hong Kong
                      </option>
                      <option value="HU">
                        Hungary
                      </option>
                      <option value="IS">
                        Iceland
                      </option>
                      <option value="IN">
                        India
                      </option>
                      <option value="ID">
                        Indonesia
                      </option>
                      <option value="IR">
                        Iran
                      </option>
                      <option value="IQ">
                        Iraq
                      </option>
                      <option value="IE">
                        Ireland
                      </option>
                      <option value="IM">
                        Isle Of Man
                      </option>
                      <option value="IL">
                        Israel
                      </option>
                      <option value="IT">
                        Italy
                      </option>
                      <option value="JM">
                        Jamaica
                      </option>
                      <option value="JP">
                        Japan
                      </option>
                      <option value="JO">
                        Jordan
                      </option>
                      <option value="KZ">
                        Kazakstan
                      </option>
                      <option value="KE">
                        Kenya
                      </option>
                      <option value="KI">
                        Kiribati
                      </option>
                      <option value="KW">
                        Kuwait
                      </option>
                      <option value="KG">
                        Kyrgystan
                      </option>
                      <option value="LA">
                        Lao People's Democratic Republic
                      </option>
                      <option value="LV">
                        Latvia
                      </option>
                      <option value="LB">
                        Lebanon
                      </option>
                      <option value="LS">
                        Lesotho
                      </option>
                      <option value="LR">
                        Liberia
                      </option>
                      <option value="LY">
                        Libyan Arab Jamahiriya
                      </option>
                      <option value="LI">
                        Liechtenstein
                      </option>
                      <option value="LT">
                        Lithuania
                      </option>
                      <option value="LU">
                        Luxembourg
                      </option>
                      <option value="MO">
                        Macau
                      </option>
                      <option value="MK">
                        Macedonia (fyrom)
                      </option>
                      <option value="MG">
                        Madagascar
                      </option>
                      <option value="MW">
                        Malawi
                      </option>
                      <option value="MY">
                        Malaysia
                      </option>
                      <option value="MV">
                        Maldives
                      </option>
                      <option value="ML">
                        Mali
                      </option>
                      <option value="MT">
                        Malta
                      </option>
                      <option value="MH">
                        Marshall Islands
                      </option>
                      <option value="MQ">
                        Martinique
                      </option>
                      <option value="MR">
                        Mauritania
                      </option>
                      <option value="MU">
                        Mauritius
                      </option>
                      <option value="YT">
                        Mayotte
                      </option>
                      <option value="MX">
                        Mexico
                      </option>
                      <option value="FM">
                        Micronesia
                      </option>
                      <option value="MD">
                        Moldova
                      </option>
                      <option value="MC">
                        Monaco
                      </option>
                      <option value="MN">
                        Mongolia
                      </option>
                      <option value="MS">
                        Montserrat
                      </option>
                      <option value="MA">
                        Morocco
                      </option>
                      <option value="MZ">
                        Mozambique
                      </option>
                      <option value="MM">
                        Myanmar
                      </option>
                      <option value="NA">
                        Namibia
                      </option>
                      <option value="NR">
                        Nauru
                      </option>
                      <option value="NP">
                        Nepal
                      </option>
                      <option value="NL">
                        Netherlands
                      </option>
                      <option value="AN">
                        Netherlands Antilles
                      </option>
                      <option value="NC">
                        New Caledonia
                      </option>
                      <option value="NZ">
                        New Zealand
                      </option>
                      <option value="NI">
                        Nicaragua
                      </option>
                      <option value="NE">
                        Niger
                      </option>
                      <option value="NG">
                        Nigeria
                      </option>
                      <option value="NU">
                        Niue
                      </option>
                      <option value="XX">
                        No Country
                      </option>
                      <option value="NF">
                        Norfolk Island
                      </option>
                      <option value="KR">
                        North Korea
                      </option>
                      <option value="MP">
                        Northern Mariana Islands
                      </option>
                      <option value="NO">
                        Norway
                      </option>
                      <option value="OM">
                        Oman
                      </option>
                      <option value="PK">
                        Pakistan
                      </option>
                      <option value="PW">
                        Palau
                      </option>
                      <option value="PS">
                        Palestine, State Of
                      </option>
                      <option value="PA">
                        Panama
                      </option>
                      <option value="PG">
                        Papua New Guinea
                      </option>
                      <option value="PY">
                        Paraguay
                      </option>
                      <option value="PE">
                        Peru
                      </option>
                      <option value="PH">
                        Philippines
                      </option>
                      <option value="PN">
                        Pitcairn
                      </option>
                      <option value="PL">
                        Poland
                      </option>
                      <option value="PT">
                        Portugal
                      </option>
                      <option value="PR">
                        Puerto Rico
                      </option>
                      <option value="QA">
                        Qatar
                      </option>
                      <option value="RE">
                        Reunion
                      </option>
                      <option value="RO">
                        Romania
                      </option>
                      <option value="RU">
                        Russia
                      </option>
                      <option value="RW">
                        Rwanda
                      </option>
                      <option value="SX">
                        Saint Maarten
                      </option>
                      <option value="WS">
                        Samoa
                      </option>
                      <option value="SM">
                        San Marino
                      </option>
                      <option value="ST">
                        Sao Tome And Principe
                      </option>
                      <option value="SA">
                        Saudi Arabia
                      </option>
                      <option value="SN">
                        Senegal
                      </option>
                      <option value="SQ">
                        Serbia And Montenegro
                      </option>
                      <option value="SC">
                        Seychelles
                      </option>
                      <option value="SL">
                        Sierra Leone
                      </option>
                      <option value="SG">
                        Singapore
                      </option>
                      <option value="SK">
                        Slovakia
                      </option>
                      <option value="SI">
                        Slovenia
                      </option>
                      <option value="SB">
                        Solomon Islands
                      </option>
                      <option value="SO">
                        Somalia
                      </option>
                      <option value="ZA">
                        South Africa
                      </option>
                      <option value="GS">
                        South Georgia And The South Sandwich
                        Islands
                      </option>
                      <option value="KP">
                        South Korea
                      </option>
                      <option value="ES">
                        Spain &amp; Canary Islands
                      </option>
                      <option value="LK">
                        Sri Lanka
                      </option>
                      <option value="SH">
                        St. Helena
                      </option>
                      <option value="KN">
                        St. Kitts And Nevis
                      </option>
                      <option value="LC">
                        St. Lucia
                      </option>
                      <option value="PM">
                        St. Pierre And Miquelon
                      </option>
                      <option value="VC">
                        St. Vincent And The Grenadines
                      </option>
                      <option value="SD">
                        Sudan
                      </option>
                      <option value="SR">
                        Suriname
                      </option>
                      <option value="SJ">
                        Svalbard And Jan Mayen Islands
                      </option>
                      <option value="SZ">
                        Swaziland
                      </option>
                      <option value="SE">
                        Sweden
                      </option>
                      <option value="CH">
                        Switzerland
                      </option>
                      <option value="SY">
                        Syrian Arab Republic
                      </option>
                      <option value="TW">
                        Taiwan
                      </option>
                      <option value="TJ">
                        Tajikistan
                      </option>
                      <option value="TZ">
                        Tanzania
                      </option>
                      <option value="TH">
                        Thailand
                      </option>
                      <option value="TG">
                        Togo
                      </option>
                      <option value="TK">
                        Tokelau
                      </option>
                      <option value="TO">
                        Tonga
                      </option>
                      <option value="TT">
                        Trinidad And Tobago
                      </option>
                      <option value="TN">
                        Tunisia
                      </option>
                      <option value="TR">
                        Turkey
                      </option>
                      <option value="TM">
                        Turkmenistan
                      </option>
                      <option value="TC">
                        Turks And Caicos Islands
                      </option>
                      <option value="TV">
                        Tuvalu
                      </option>
                      <option value="VI">
                        U.s. Virgin Islands
                      </option>
                      <option value="UG">
                        Uganda
                      </option>
                      <option value="UA">
                        Ukraine
                      </option>
                      <option value="AE">
                        United Arab Emirates
                      </option>
                      <option value="UM">
                        United States Minor Outlying Islands
                      </option>
                      <option value="UY">
                        Uruguay
                      </option>
                      <option value="UZ">
                        Uzbekistan
                      </option>
                      <option value="VU">
                        Vanuatu
                      </option>
                      <option value="VA">
                        Vatican City State
                      </option>
                      <option value="VE">
                        Venezuela
                      </option>
                      <option value="VN">
                        Viet Nam
                      </option>
                      <option value="WF">
                        Wallis And Futuna Islands
                      </option>
                      <option value="EH">
                        Western Sahara
                      </option>
                      <option value="YE">
                        Yemen
                      </option>
                      <option value="YU">
                        Yugoslavia
                      </option>
                      <option value="ZR">
                        Zaire
                      </option>
                      <option value="ZM">
                        Zambia
                      </option>
                      <option value="ZW">
                        Zimbabwe
                      </option>
                    </Field>
                    <ErrorMessage name="country" class="text-red-700 bg-red-200 p-2 rounded" />
                  </div>
                </div>
                <div
                  v-if="form.country === 'US'" class="flex flex-col md:flex-row mb-2"
                >
                  <div class="flex flex-col space-y-2">
                    <Field
                      v-model="form.state"
                      as="select"
                      name="state"
                      class="w-full md:w-auto border-transparent bg-gray-100 text-gray-700"
                    >
                      <option
                        value
                        selected="selected"
                        disabled
                      >
                        {{
                          $t(
                            'select-state',
                          )
                        }}
                      </option>
                      <option value="AL">
                        Alabama
                      </option>
                      <option value="AK">
                        Alaska
                      </option>
                      <option value="AZ">
                        Arizona
                      </option>
                      <option value="AR">
                        Arkansas
                      </option>
                      <option value="CA">
                        California
                      </option>
                      <option value="CO">
                        Colorado
                      </option>
                      <option value="CT">
                        Connecticut
                      </option>
                      <option value="DE">
                        Delaware
                      </option>
                      <option value="DC">
                        District Of Columbia
                      </option>
                      <option value="FL">
                        Florida
                      </option>
                      <option value="GA">
                        Georgia
                      </option>
                      <option value="HI">
                        Hawaii
                      </option>
                      <option value="ID">
                        Idaho
                      </option>
                      <option value="IL">
                        Illinois
                      </option>
                      <option value="IN">
                        Indiana
                      </option>
                      <option value="IA">
                        Iowa
                      </option>
                      <option value="KS">
                        Kansas
                      </option>
                      <option value="KY">
                        Kentucky
                      </option>
                      <option value="LA">
                        Louisiana
                      </option>
                      <option value="ME">
                        Maine
                      </option>
                      <option value="MD">
                        Maryland
                      </option>
                      <option value="MA">
                        Massachusetts
                      </option>
                      <option value="MI">
                        Michigan
                      </option>
                      <option value="MN">
                        Minnesota
                      </option>
                      <option value="MS">
                        Mississippi
                      </option>
                      <option value="MO">
                        Missouri
                      </option>
                      <option value="MT">
                        Montana
                      </option>
                      <option value="NE">
                        Nebraska
                      </option>
                      <option value="NV">
                        Nevada
                      </option>
                      <option value="NH">
                        New Hampshire
                      </option>
                      <option value="NJ">
                        New Jersey
                      </option>
                      <option value="NM">
                        New Mexico
                      </option>
                      <option value="NY">
                        New York
                      </option>
                      <option value="NC">
                        North Carolina
                      </option>
                      <option value="ND">
                        North Dakota
                      </option>
                      <option value="OH">
                        Ohio
                      </option>
                      <option value="OK">
                        Oklahoma
                      </option>
                      <option value="OR">
                        Oregon
                      </option>
                      <option value="PA">
                        Pennsylvania
                      </option>
                      <option value="RI">
                        Rhode Island
                      </option>
                      <option value="SC">
                        South Carolina
                      </option>
                      <option value="SD">
                        South Dakota
                      </option>
                      <option value="TN">
                        Tennessee
                      </option>
                      <option value="TX">
                        Texas
                      </option>
                      <option value="UT">
                        Utah
                      </option>
                      <option value="VT">
                        Vermont
                      </option>
                      <option value="VA">
                        Virginia
                      </option>
                      <option value="WA">
                        Washington
                      </option>
                      <option value="WV">
                        West Virginia
                      </option>
                      <option value="WI">
                        Wisconsin
                      </option>
                      <option value="WY">
                        Wyoming
                      </option>
                    </Field>
                    <ErrorMessage name="state" class="text-red-700 bg-red-200 p-2 rounded" />
                  </div>
                </div>
              </div>
            </template>

            <div v-if="!success.show">
              <div class="flex flex-col space-y-2">
                <label class="normal-case  space-x-2">

                  <Field
                    type="checkbox"
                    name="IsTravelAgent"
                    :value="true"
                  />
                  <span class="text-sm">{{ $t('i-am-a-travel-agent') }}</span>

                </label>
                <ErrorMessage name="IsTravelAgent" class="text-red-700 bg-red-200 p-2 rounded" />
              </div>
              <div class="flex flex-col space-y-2">
                <label class="normal-case flex space-x-2">
                  <Field type="checkbox" name="TCAgree" :value="true" />
                  <span
                    v-if="form.country === 'CA'"
                    class="text-sm"
                    v-html="
                      $t(
                        'i-want-to-receive-electronic-communications-ca',
                      )
                    "
                  />
                  <span
                    v-else
                    class="text-sm"
                    v-html="
                      $t(
                        'i-want-to-receive-electronic-communications',
                      )
                    "
                  />
                </label>

                <ErrorMessage name="TCAgree" class="text-red-700 bg-red-200 p-2 rounded" />
              </div>
            </div>

            <div v-if="!success.show">
              <input
                type="submit"
                class="btn btn-inverse"
                :value="$t('subscribe')"
              >
            </div>
          </VeeForm>
        </Modal>
      </Teleport>
    </div>
  </div>
  <div
    class="relative content flex items-center text-center justify-center p-8 overflow-hidden"
    style="min-height: 100px"
  >
    <div class="mr-8 sp20">
      <div
        class="uppercase mb-1 text-xl font-bold"
        :style="{ color: settings.bottom.foreground }"
      >
        <template v-if="!relaxed">
          {{
            $t('sign-up-to-save')
          }}
        </template>
        <template v-else>
          {{
            $t('sign-up-to-save-relaxed')
          }}
        </template>
      </div>

      <p
        style="padding-bottom: 0"
        :style="{ color: settings.bottom.foreground }"
      >
        {{ $t('receive-via-email') }}
      </p>
    </div>

    <slot name="button">
      <button
        class="py-4 px-6 uppercase font-bold "
        :style="{
          backgroundColor: settings.bottom.foreground,
          color: settings.bottom.background,
        }"
        @click="showForm = true"
      >
        {{ $t('subscribe') }}
      </button>
    </slot>
  </div>
</template>
